//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import selectPersonByLabel from './selectPersonByLabel.vue'
export default {
  components: {
    selectPersonByLabel,
  },
  data() {
    return {
      visible: false,
      key: '',
      index: -1,
      all_list: [],
    }
  },
  methods: {
    todoFromBook() {
      this.$emit('selectTodoMsg', {
        type: 'book',
        data: {
          key: this.key,
          index: this.index,
          all_list: this.all_list,
        },
      })
      this.visible = false
    },
    submit(data) {
      const result = [...new Set(data)].map((j) => ({
        corpId: j.corpId,
        ...this.getDataKeyAndValue(j),
        type: j.deptId ? 'departmentName' : 'userName',
      }))
      this.$emit('selectTodoMsg', {
        type: 'label',
        data: {
          key: this.key,
          index: this.index,
          result,
        },
      })
      this.visible = false
    },
    getDataKeyAndValue(node) {
      const key = node.deptId ? 'id' : 'userId'
      return {
        [key]: node.deptId || node.userId,
      }
    },
    handleCancel() {
      this.visible = false
    },
    toSelectLabel() {
      this.$refs.selectPersonByLabel.open(this.key, this.index, [])
    },
    open({ key, index, all_list }) {
      this.visible = true
      this.key = key
      this.all_list = all_list
      this.index = index
    },
  },
}
